.container {
    width: 100%;
    padding-right: 4%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1000;
    /*background-color: #181c45;*/
    height: 10%;
    grid-area: header;
    }

 .genericContainer {
     composes: container;
     background-color: #FAFAFA;
 }   

    .customizeContainer {
        composes: container;
        background-color: #FAFAFA;
        border-bottom: 1px solid #E6E9EF;
    }

    @media screen and (min-width: 768px) {
        .container {
        height: auto;
        }
      }