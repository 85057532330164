.container {
width: 360px;
display: block;
margin-left: auto;
margin-right: auto;
background: #FFFFFF;
border: 1px solid rgba(191, 203, 218, 0.25);
box-sizing: border-box;
box-shadow: 0px 3px 8px rgba(191, 203, 218, 0.125);
border-radius: 14px;
filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.010691));
}

.containerSignup {
  composes: container;
  height: 340px;
}

.containerLoginSmall {
  composes: container;
  height: 279px;
}

.containerLogin {
  composes: container;
  height: 340px;
}

.containerLoginError {
  composes: container;
  height: 279px;
  animation: heightIncreaseLogin 0.5s ease-out forwards;
}

.containerSignupError {
  composes: container;
  height: 390px;
  animation: heightIncrease 0.5s ease-out forwards;
  }

.loginText {
    composes: typography from './commonStyles.module.css';
    display: block;
    margin-left: 25px;
    margin-top: 23px;
    font-size: 16px;
}

.errorText {
  composes: typography from './commonStyles.module.css';
  display: none;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: red;
}

.verificationText {
  composes: typography from './commonStyles.module.css';
  display: block;
  margin-left: 25px;
  margin-top: 5px;
  font-size: 16px;
  text-align: center;
  padding-top: 80px;
}

.input {
composes: typography from './commonStyles.module.css';
background: #FAFCFF;
border: 1px solid rgba(191, 203, 218, 0.5);
box-sizing: border-box;
border-radius: 4px;
height: 38px;
width: 310px;
display: block;
/*margin-left: auto;
margin-right: auto;*/
margin-top: 6px;
outline: none;
font-weight: normal;
font-size: 18px;
padding-left: 10px;
}

.emailInput {
  composes: input;
  margin-top: 13px;
}

.input::placeholder { /* Most modern browsers support this now. */
    font-family: Poppins;
    font-style: normal;
    color: rgba(0, 0, 0, 0.2);
    padding: 0;
    margin: 0;
 }

 .loginButtonDisabled {
  composes: typography from './commonStyles.module.css';
  border: none;
  outline: none;
  background: #D7D7D7;
  border-radius: 37px;
  width: 237px;
  height: 41px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 22px;
  color: white;
  font-size: 15px;
  font-weight: 450;
 }

 .loginButton {
    composes: loginButtonDisabled;
    background: #13BCB4;
    cursor: pointer;
 }

 .loginButton:hover {
    background: #0F9790;
    transition: background-color 100ms linear;
    text-decoration: none;
  }

  .codeInput {
    composes: typography from './commonStyles.module.css';
    background: #FFFFFF;
    border: 1px solid rgba(191, 203, 218, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    height: 44px;
    width: 138px;
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    outline: none;
    font-size: 14px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.010691));
    padding-left: 10px;
    text-align: center;
  }

  .codeInputActive {
    composes: typography from './commonStyles.module.css';
    background: #FFFFFF;
    border: 1px solid rgba(191, 203, 218, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    height: 44px;
    width: 138px;
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    outline: none;
    font-size: 28px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.010691));
    padding-left: 10px;
    text-align: center;
    word-spacing: 4px;
    letter-spacing: 4px;
  }

  .codeInput::placeholder { /* Most modern browsers support this now. */
    font-family: Poppins;
    font-style: normal;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    padding: 0;
    text-align: center;
 }

 .signUpContainer {
 
}

 .verificationContainer {
  background-image: url(../public/images/emailSent.svg);
  background-position: 50% +15px;
  background-repeat: no-repeat;
  background-size: 3rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 1%;
  background-color: white;
  /*transform: translateY(0);
  transition: transform 0.5s ease-out;*/
  animation: verificationSlide 0.5s linear 0s;
 }

 .verificationContainerClosed {
  background-image: url(../public/images/lock.jpg);
  background-position: 50% -50px;
  background-repeat: no-repeat;
  background-size: 8rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 1%;
  background-color: white;
 /* transform: translateY(-200%);
  transition: transform 0.5s ease-out;*/ 
  display: none;
 }

 .lineProgressBar {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 3px;
  overflow-x: hidden;
  background: #0F9790;
  height: 3px;
  animation: slidein 0.8s linear 0s infinite;
 }

 .lineProgressBarHide {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50.5%;
  height: 3px;
  overflow-x: hidden;
  background: #0F9790;
  height: 3px;
  display: none;
 }

 .sendEmailAgain {
   composes: typography from './commonStyles.module.css';
   display: block;
   text-align: center;
   margin-top: 10px;
   font-weight: 450;
   font-size: 12px;
   text-decoration: underline;
   cursor: pointer;
 }

.googleLogin {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.googleButton {
  display: flex;
  height: 50px;
  width: 244px;
  align-items: center;
  justify-content: center;
  border: 2px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 37.2352px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto; 
  background: white;
  margin-bottom: 31px;
  transition: background-color 0.2s linear;
  margin-top: 20px;
}

.googleButton:hover {
  background: rgb(240,240,240);
  transition: background-color 0.2s linear;
}

.authIcon {
  margin-right: 15px;
}

.googleIconText {
composes: typography from './commonStyles.module.css';
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
letter-spacing: 0.15px;
color: #323232;
}

.signUpWithEmail {
composes: typography from './commonStyles.module.css';
font-size: 14px;
line-height: 18px;
text-align: center;
letter-spacing: 0.3px;
text-decoration-line: underline;
color: #74787B;
margin-top: 22px;
cursor: pointer;
display: block;
}

.separatorContainer {
  position: relative;
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.separator {
  border: 1px solid #E1E1E1;
}

.separatorText {
  composes: typography from './commonStyles.module.css';
  background: white;
  width: 13%;
  height: 22px;
  z-index: 1000;
  position: absolute;
  top: -10px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #74787B;
  left: 45%;
}

.emailLoginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
}

.emailLoginInput {
  composes: typography from './commonStyles.module.css';
  width: 50%;
  height: 36px;
  background: #FAFAFA;
  border: 1.42296px solid #E1E1E1;
  outline: none;
  padding-left: 14px;
  font-size: 14px;
  line-height: 23px;
  margin-right: 8px;
}

.emailLoginInput::placeholder {
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 23px;
color: #CBCFD2;
padding: 0;
}

.emailLoginButtonDisabled {
  composes: typography from './commonStyles.module.css';
  width: 26%;
  height: 36px;
  background: #D7D7D7;
  border-radius: 32.7606px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.131974px;
  color: #FFFFFF;
  border: none;
  outline: none;
  display: block;
}

.emailLoginButton {
  composes: emailLoginButtonDisabled;
  background-color: #13BCB4;
  cursor: pointer;
}

.checkboxAnswersItemsContainer {
  display: block;
  /*padding-left: 26px;
  margin-top: 42px; */
 position: relative;
 top: 13px;
}

.answerItem {
 display: block;
 margin-bottom: 13px;
}

.answerText {
 composes: typography from './commonStyles.module.css';
 position: relative;
 bottom: 3px;
 margin-left: 6px;
 font-size: 14px;
 line-height: 18px;
 letter-spacing: 0.3px;
 color: #74787B;
}

.termsLink {
  composes: typography from './commonStyles.module.css';
  position: relative;
  bottom: 3px;
  margin-left: 6px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #74787B;
  display: contents;
  text-decoration: underline;
  color: #13BCB4;
  font-size: 14px;
 }

 .signupForm {
   display: block;
   margin-left: auto;
   margin-right: auto;
   width: max-content;
 }

 .spinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  bottom: 3px;
}

.loginLink {
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #13BCB4;
  text-decoration: underline;
}


 @keyframes slidein {
  from { width: 0%; } to { width: 100%; }
}

@keyframes verificationSlide {
  from { margin-top: 20%; } to { margin-top: 0%; }
}

@keyframes heightIncrease {
  from { height: 340px; } to { height: 390px; }
}

@keyframes heightIncreaseLogin {
  from { height: 279px; } to { height: 212px; }
}
