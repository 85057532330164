.container {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.userName {
composes: typography from './commonStyles.module.css';
font-size: 22px;
line-height: 28px;
text-align: center;
letter-spacing: 0.3px;
color: #323232;
margin-bottom: 30px;
display: block;
}

.userName::first-letter {
  text-transform: uppercase;
}

.cancelSubscription {
composes: typography from './commonStyles.module.css';
font-size: 14px;
line-height: 26px;
letter-spacing: 0.425353px;
text-decoration-line: underline;
color: #13BCB4;
cursor: pointer;
position: relative;
top: 10px;
}

.cancelConfirmationText {
  composes: typography from './commonStyles.module.css';
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.425353px;
  color: #323232;
  position: relative;
  top: 20px;
}

.confirmCancelSelectionChips {
  display: flex;
  justify-content: center;
  position: relative;
  top: 40px;
}

.cancelConfirmationContainer {
  border-top: 1px solid #E6E9EF ;
  margin-top: 26px;
}

.cancellationConfirmed {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.cancellationConfirmedText {
composes: typography from './commonStyles.module.css';
font-size: 22px;
line-height: 28px;
text-align: center;
letter-spacing: 0.3px;
color: #323232;
margin-top: 13px;
display: block;
}

.ctaLink {
  composes: typography from './commonStyles.module.css';
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 195px;
  height: 45px;
  background: #13BCB4;
  border-radius: 37.2352px;
  margin-top: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  top: 18px;
}

.ctaLink:hover {
  background: #0F9790;
}

.ctaLinkText {
  composes: typography from './commonStyles.module.css';
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  position: relative;
  bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  padding-left: 10px;
  color: #FFFFFF;
}