.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  z-index: 50;
  position: fixed;
  border-radius: 0px 0px 8px 8px;
}

.loader {
	-webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
  height: 3rem;
  width: 3rem;
  border-top-width: 4px;
  border-width: 4px;
  border-radius: 9999px;
  box-sizing: border-box;
  border-style: inset;
  border-color: rgba(255, 255, 255, 1);
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


