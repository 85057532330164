.googleButton {
  display: flex;
  height: 50px;
  width: 244px;
  align-items: center;
  justify-content: center;
  border: 2px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 37.2352px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto; 
  background: white;
  margin-bottom: 31px;
  transition: background-color 0.2s linear;
  margin-top: 20px;
  outline: none;
}

.googleButton:hover {
  background: rgb(240,240,240);
  transition: background-color 0.2s linear;
}

.authIcon {
  margin-right: 15px;
}

.googleIconText {
composes: typography from './commonStyles.module.css';
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
letter-spacing: 0.15px;
color: #323232;
}