.container {
  /*background-image: url(../public/images/emailSent.svg);
  background-position: 50% -1px;
  background-repeat: no-repeat;*/
  top: 0.1%;
  background-color: white;
  /*transform: translateY(0);
  transition: transform 0.5s ease-out;*/
  animation: verificationSlide 0.5s linear 0s;
  display: block;
  position: relative;
  height: 100%;
  border-radius: 16px;
 }

 .containerClosed {
  /*background-image: url(../public/images/lock.jpg);
  background-position: 50% -50px;
  background-repeat: no-repeat;
  background-size: 8rem;*/
  width: 100%;
  height: 100%;
  position: absolute;
  top: 1%;
  background-color: white;
 /* transform: translateY(-200%);
  transition: transform 0.5s ease-out;*/ 
  display: none;
 }

.verificationTitle {
  composes: typography from './commonStyles.module.css';
  margin-top: 10px;
  font-size: 22px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #323232;
  text-align: center;
  display: block;
}

.verificationText {
composes: typography from './commonStyles.module.css';
font-size: 15px;
line-height: 18px;
text-align: center;
letter-spacing: 0.3px;
color: #74787B;
display: block;
margin-top: 7px;
}

.codeInput {
  composes: typography from './commonStyles.module.css';
  background: #FAFAFA;
  border: 1.42296px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 4px;
  height: 45.36px;
  width: 220px;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  font-size: 14px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.010691));
  padding-left: 10px;
  text-align: center;
}

  .codeInputActive {
    composes: typography from './commonStyles.module.css';
    background: #FAFAFA;
    border: 1.42296px solid #74787B;
    box-sizing: border-box;
    border-radius: 4px;
    height: 45.36px;
    width: 178px;
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    outline: none;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.010691));
    padding-left: 10px;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 6px;
    color: #323232;
  }

  .codeInputDisabled {
    composes: codeInputActive;
    opacity: 0.5;
  }

  .codeInputError{
    composes: codeInputActive;
    border: 1.42296px solid #FF3B4D;
  }

  .codeInput::placeholder { /* Most modern browsers support this now. */
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    padding: 0;
    color: #CBCFD2;
 }

 .codeInput:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 46px;
  text-align: center;
  padding: 0;
  color: #CBCFD2;
}
.codeInput::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 46px;
  text-align: center;
  padding: 0;
  color: #CBCFD2;
}
.codeInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  padding: 0;
  color: #CBCFD2;
}

 .codeInput::-webkit-outer-spin-button,
 .codeInput::-webkit-inner-spin-button,
 .codeInputActive::-webkit-outer-spin-button,
 .codeInputActive::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.codeInput[type=number] {
  -moz-appearance: textfield;
}
.codeInputActive[type=number] {
  -moz-appearance: textfield;
}

 .lineProgressBar {
  position: absolute;
  bottom: 10px;
  left: 5px;
  width: 100%;
  height: 4px;
  border-radius: 50px;
  overflow-x: hidden;
  background: #13BCB4;
  animation: slidein 0.8s linear 0s infinite;
 }

 .lineProgressBarHide {
  position: absolute;
  top: 0px;
  left: 5px;
  width: 50.5%;
  height: 4px;
  overflow-x: hidden;
  background: #0f7aff;
  display: none;
 }

 .sendEmailAgain {
   composes: typography from './commonStyles.module.css';
   display: block;
   width: fit-content;
   margin-left: auto;
   margin-right: auto;
   margin-top: 2vh;
   text-align: center;
   color: #13BCB4;
   cursor: pointer;;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 18px;
   text-align: center;
   letter-spacing: 0.3px;
   text-decoration-line: underline;
 }

 .sendEmailAgainAnimatedDown {
  composes: typography from './commonStyles.module.css';
  display: block;
  text-align: center;
  margin-top: 1vh;
  color: #13BCB4;
  cursor: pointer;;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
  animation: slideDown 0.5s linear 0s forwards;
}

.continueWithPhone {
  composes: typography from './commonStyles.module.css';
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #13BCB4;
  cursor: pointer;;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
  position: relative;
  top: 60px;
}

.continueWithPhoneDisabled {
  composes: continueWithPhone;
  color: rgb(215, 215, 215);
  cursor: default;
}

.getSMS {
  composes: typography from './commonStyles.module.css';
  width: fit-content;
  text-align: center;
  color: #13BCB4;
  cursor: pointer;;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
}

 .emailSentImage {
   display: block;
   margin-left: auto;
   margin-right: auto;
 }

 .errorText {
  composes: typography from './commonStyles.module.css';
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5vh;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #74787B;
 }

 .errorTextHidden {
   composes: errorText;
   display: none;
 }

 .getLink {
   display: flex;
   position: relative;
   right: 5px;
 }

 .getCodeViaSMS {
  display: flex;
  justify-content: center;
  position: relative;
  top: 10px;
}

 .linkIcon {
  position: relative;
  top: 18px;
  left: 70px;
 }

 .smsIcon {
  position: relative;
  right: 6px;
  top: 3px;
 }

 @keyframes slidein {
  from { width: 0%; } to { width: 100%; }
}

@keyframes verificationSlide {
  from { margin-top: 20%; } to { margin-top: 0%; }
}

@keyframes slideDown {
  from { bottom: -1vh; } to { bottom: -6vh; }
}
