.container {
  position: fixed;
  width: 100%;
  padding-right: 14%;
  padding-left: 14%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  z-index: 1000;
  }

  .nav {
    composes: typography from './commonStyles.module.css';
    position: relative;
    display: flex;
    z-index: 1;
    font-weight: normal;
    text-align: center;
    width: auto;
    padding-left: 10px;
}

.navHidden {
  display: none;
}

@media screen and (min-width: 768px) {
  .nav {
    float: right;
    padding-left: 0;
    padding-right: 20px;
}
}

.logo {
  composes: typography from './commonStyles.module.css';
  display: none;
}

@media screen and (min-width: 768px) {
  .logo {
    position: relative;
    bottom: 12px;
    display: inline-block;
    width: 0vh;
    height: 0vh;
    padding-top: 45px;
    margin-left: 57px;
    border: none;
    outline: none;
    z-index: 1;
    text-decoration: none !important;
    font-weight: 700;
    font-size: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #13BCB4;
  }
}

.previewModeLogo {
  composes: logo;
  margin-left: 17px;
}

.addYourBrand {
    height: 44px;
    border: 2px solid #13BCB4;
    box-sizing: border-box;
    border-radius: 37.2352px;
    padding-top: 8px;
    text-decoration: none !important;
    top: 10px;
    font-weight: 600;
}

.genericAddYourBrand {
  composes: addYourBrand;
  border: 2px solid #323232;
}

.linkStyle {
  padding-right: 45px;
}

.genericLogo {
  composes: logo;
  color: #323232;
}

.hideLink {
  display: none;
}

.driveMoreFileLink {
  composes: typography from './commonStyles.module.css';
  background: #FFFFFF;
  border: 2px solid #13BCB4;
  box-sizing: border-box;
  border-radius: 37.2352px;
  position: relative;
  left: 180px;
  width: fit-content;
  top: 10px;
  min-width: 204px;
  height: 35px;
  text-align: center;
  width: 115px;
  height: 45px;
  padding: 0 6px 0 .4rem;
  display: inline-block;
  align-items: center;
  margin: 0 0.8rem 0.3rem 0;
  opacity: 0.8;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: #13BCB4;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  position: relative;
  text-align: center;
  outline: none;
  cursor: pointer;
}

.driveMoreFileLink:hover {
  background: rgba(19, 188, 180, 0.2);
}

.driveMoreFileLinkClicked {
  composes: driveMoreFileLink;
  background-color: #13BCB4;
  color: #FFFFFF;
}

.driveMoreFileLinkClicked:hover{
  background: #13BCB4;
}

.driveMoreFileLinkContent {
  display: flex;
}

.logoWrapper {
  display: inline-flex;
  width: 0;
}