/*@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900italic,3300,400,500,600,700,800,900);*/

.typography {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0.3px;
}

.inputTextColor {
    color: #323232;
}

.styledButton {
    background: #13BCB4;
    border-radius: 37.2352px;
    width: 131px;
    height: 45px;
    border: none;
    outline: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    bottom: 8px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
  }
