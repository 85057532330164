.chip {
    composes: typography from './commonStyles.module.css';
    background: #FFFFFF;
    border: 2px solid #13BCB4;
    box-sizing: border-box;
    border-radius: 37.2352px;

    width: 115px;
    height: 45px;
    padding: 0 6px 0 .4rem;
    display: inline-block;
    align-items: center;
    margin: 0 0.8rem 0.3rem 0;
    opacity: 0.8;

    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #13BCB4;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    position: relative;
    text-align: center;
    outline: none;
    cursor: pointer;
  }

  .chip:hover {
    background: rgba(19, 188, 180, 0.2);
  }

  .chipClicked {
    composes: chip;
    background-color: #13BCB4;
    color: #FFFFFF;
  }

  .chipClicked:hover{
    background: #13BCB4;
  }

  .chipContent {
    display: flex;
  }