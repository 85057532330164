.container {
align-self: baseline;
top: 34vh;
padding-bottom: 3em;
width: 280px;
background: #FFFFFF;
border-radius: 16px;
position: relative;
margin-left: 24px;
display: flex;
animation: fadeInFromNone 0.5s ease-in;
z-index: 1;
min-height: 350px;
z-index: 100;
}

.containerFadeOut{
  composes: container;
  animation: fadeOut 0.3s ease-in forwards;
  animation-delay: 30s;
}

.pointer {
  position: absolute;
  left: -69px;
  top: 25%;
}

.toolTipContent {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-top: 38px;
}

.upwardsPointer {
  position: absolute;
    float: right;
    right: 20px;
    top: -80px;
    transform: rotate(90deg);
}

@keyframes fadeInFromNone {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
      display: block;
      opacity: 1;
  }

  100% {
      display: none;
      opacity: 0;
  }
}